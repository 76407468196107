<template>
  <div class="form-table">
    <h4>Деталі
    </h4>
    <form id="detailTableForm" class="table-wrapper" data-simplebar>
      <table id="main_table" class="main_table table table-bordered">
        <thead>
        <tr>
          <th rowspan="2" style="min-width: 22px !important;">№</th>
          <th rowspan="2" style="min-width:80px !important;">Опції</th>
          <th colspan="2" style="min-width:62px !important;">Довжина</th>
          <th colspan="2" style="min-width:62px !important;">Ширина</th>
          <th rowspan="2" style="min-width:40px !important;">К-ть</th>
          <th rowspan="2" style="min-width:30px !important;" v-b-tooltip.hover.right="'Обертати'">
                            <span>
                                <i class="fa fa-sync"></i>
                                <br>
                                <i class="fa fa-xs fa-question-circle"
                                ></i>
                            </span>
            <input type="checkbox" id="rotate_all" @change="rotateAll" class="checkbox-input">
            <label for="rotate_all"></label>
          </th>
          <th colspan="4" style="min-width:720px !important;max-width:720px !important;">Кромка</th>
          <th rowspan="2" style="min-width:242px !important;">Матеріал</th>
          <th colspan="3" style="min-width:91px !important;">Стяжка</th>

          <th rowspan="2" style="min-width:82px !important;">
                            <span>
                                Порізка за <br> текстурою
                            </span>
          </th>
          <th rowspan="2" style="min-width:120px !important;">Шаблон</th>
          <th rowspan="2" style="min-width:110px !important;">Виріб</th>
          <th rowspan="2" style="min-width:110px !important;">Деталь</th>
        </tr>
        <tr>
          <td style="min-width:70px !important;">мм</td>
          <td style="min-width:37px !important;"
              v-b-tooltip.hover.right="'Кількість сторін, що обклеюються, за довжиною'">К
            <i class="fa fa-xs fa-question-circle"
            ></i>
          </td>

          <td style="min-width:70px !important;">мм</td>
          <td style="min-width:37px !important;"
              v-b-tooltip.hover.right="'Кількість сторін, що обклеюються, за шириною'">К
            <i class="fa fa-xs fa-question-circle"
            ></i>
          </td>

          <td style="min-width: 180px !important;max-width: 180px !important;">Верх</td>
          <td style="min-width: 180px !important;max-width: 180px !important;">Низ</td>
          <td style="min-width: 180px !important;max-width: 180px !important;">Ліворуч</td>
          <td style="min-width: 180px !important;max-width: 180px !important;">Праворуч</td>

          <td style="min-width: 62px !important;"
              v-b-tooltip.hover.right="'0 - Відсутня, 1-Звичайна, >1-Економ (Обов\'язковий вибір Базової деталі \'БД\')'"> <span>
                                Тип <i class="fa fa-xs fa-question-circle"
          ></i>
                            </span>
          </td>
          <td colspan="2" style="min-width: 41px !important;"
              v-b-tooltip.hover.right="'Базова деталь для Економ стяжки'">БД
            <i class="fa fa-xs fa-question-circle"
            ></i>
          </td>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(detail, index) in details" :data-key="index" :key="index"
            :class="index === activeIndex ? 'active' : ''"
            @keyup="visual(index)" @mousedown="visual(index)">
          <td style="min-width: 22px !important;">{{ index + 1 }}</td>
          <td style="min-width: 80px !important;">
            <div class="btn-group">

              <b-button size="sm" v-b-tooltip.hover.right="'Копіювати'"
                        @click="copyDetail(index)"
                        variant="outline-info"><i class="far fa-copy"></i></b-button>

              <b-button size="sm" variant="outline-danger"
                        v-b-tooltip.hover.right="'Видалити'"
                        @click="removeDetail(index)"
              >
                <i class="far fa-trash-alt"></i>
              </b-button>

            </div>
          </td>

          <td style="min-width: 70px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'L_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'L_')">
            <input type="text" required step="0.1" min="20" max="9999.9" lang="en-150" @keyup="onlyNumeric"
                   @keyup.enter="focusNextInput" @click="ifSelect($event)" v-model.number="detail.length"/>
          </td>
          <td style="min-width: 37px !important;">
            <input type="text" @keyup="onlyNumeric" step="1" required min="0" max="2"
                   :value="(detail.edge_b != null && detail.edge_t != null) ? 2 : (detail.edge_t != null ? 1 : 0)"
                   @input="addEdge($event.target.value, index, 1)"
                   @keyup.enter="focusNextInput" @click="ifSelect($event)" class="sm-one"/>
          </td>
          <td style="min-width: 70px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'W_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'W_')">
            <input type="text" required step="0.1" min="20" max="9999.9" lang="en-150" @keyup="onlyNumeric"
                   @keyup.enter="focusNextInput" @click="ifSelect($event)" v-model.number="detail.width"/>
          </td>
          <td style="min-width: 37px !important;">
            <input type="text" @keyup="onlyNumeric" required step="1" min="0" max="2"
                   :value="(detail.edge_r != null && detail.edge_l != null) ? 2 : (detail.edge_l != null ? 1 : 0)"
                   @input="addEdge($event.target.value, index, 2)"
                   @keyup.enter="focusNextInput" @click="ifSelect($event)" class="sm-one"/>
          </td>
          <td style="min-width: 40px !important;" :class="{'red tooltiper': detailValidation(detail.No, 'Qt_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'Qt_')">
            <input required type="text" @keyup="onlyNumeric" step="1" min="1" lang="en-150"
                   @keyup.enter="focusNextInput"
                   @click="ifSelect($event)" v-model.number="detail.count" class="one-qt"/>
          </td>
          <td style="min-width: 30px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'Rotate_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'Rotate_')">
            <input type="checkbox" :id="'Rotate_'+index" class="checkbox-input"
                   v-model.number="detail.rotate"
                   @keyup.enter="focusNextInput"
                   @keydown="checkbox($event, 'rotate')"
                   @change="checkboxChange($event.target.checked, 'rotate')">
            <label :for="'Rotate_'+index"></label>
          </td>

          <td style="min-width: 180px !important;max-width: 180px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'EdgeT_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'EdgeT_')">
            <select :style="{color:edges.find(el => el.ref === detail.edge_t)?.color || 'black'}"
                    class="custom-select three"
                    v-model="detail.edge_t"
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, edges, 'edge_t')">
              <option value="null">Ні</option>
              <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                {{ (i + 1) + ' ' }} {{ edge.full_name }}
              </option>
            </select>
          </td>
          <td style="min-width: 180px !important;max-width: 180px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'EdgeB_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'EdgeB_')">
            <select :style="{color:edges.find(el => el.ref === detail.edge_b)?.color || 'black'}"
                    class="custom-select three"
                    v-model="detail.edge_b"
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, edges, 'edge_b')">
              <option value="null">Ні</option>
              <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                {{ (i + 1) + ' ' }} {{ edge.full_name }}
              </option>
            </select>
          </td>
          <td style="min-width: 180px !important;max-width: 180px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'EdgeL_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'EdgeL_')">
            <select :style="{color:edges.find(el => el.ref === detail.edge_l)?.color || 'black'}"
                    class="custom-select three"
                    v-model="detail.edge_l"
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, edges, 'edge_l')">
              <option value="null">Ні</option>
              <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                {{ (i + 1) + ' ' }} {{ edge.full_name }}
              </option>
            </select>
          </td>
          <td style="min-width: 180px !important;max-width: 180px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'EdgeR_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'EdgeR_')">
            <select :style="{color:edges.find(el => el.ref === detail.edge_r)?.color || 'black'}"
                    class="custom-select three"
                    v-model="detail.edge_r"
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, edges, 'edge_r')">
              <option value="null">Ні</option>
              <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                {{ (i + 1) + ' ' }} {{ edge.full_name }}
              </option>
            </select>
          </td>

          <td style="min-width: 180px !important;width: 242px;">
            <select class="custom-select three material"
                    v-model="detail.material"
                    required
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, materials, 'material')">
              <option value="" disabled>Виберіть*</option>
              <option v-for="(material, i) in materials" :value="material.ref" :key="i">
                {{ (i + 1) + ' ' }} {{ material.full_name }}
              </option>
            </select>
          </td>

          <td style="min-width: 62px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'Screed_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'Screed_')">
            <select class="custom-select one"
                    v-model.number="detail.screed"
                    @keyup.enter="focusNextInput"
                    @keydown="selectByKey($event, screeds, 'screed')">
              <option value="0">0</option>
              <option value="1">1</option>
              <option v-for="(screed, i) in screeds" :value="screed.No" :key="i">{{ screed.No }}
              </option>
            </select>
          </td>
          <td style="min-width: 41px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'ScreedBP_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'ScreedBP_')">
            <input type="checkbox" :id="'ScreedBP_'+index" class="checkbox-input"
                   v-model.number="detail.screed_bp"
                   @keyup.enter="focusNextInput"
                   @keydown="checkbox($event, 'screed_bp')"
                   @change="checkboxChange($event.target.checked, 'screed_bp')">
            <label :for="'ScreedBP_'+index"></label>
          </td>

          <td style="min-width: 82px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'CutToTxtrs_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'CutToTxtrs_')">
            <input type="checkbox" :id="'CutToTxtrs_'+index" class="checkbox-input"
                   v-model.number="detail.cut_to_txtrs"
                   @keyup.enter="focusNextInput"
                   @keydown="checkbox($event, 'cut_to_txtrs')"
                   @change="checkboxChange($event.target.checked, 'cut_to_txtrs')">
            <label :for="'CutToTxtrs_'+index"></label>
          </td>
          <td style="min-width: 120px !important;"
              :class="{'red tooltiper': detailValidation(detail.No, 'Template_')}"
              v-b-tooltip.hover.right="detailValidation(detail.No, 'Template_')">
            <b-form-select required id="baseTemplate" v-model.number="detail.template"
                           @change="pClear(index)">
              <b-form-select-option value="1">Відсутній</b-form-select-option>
              <b-form-select-option value="2">Косі різи</b-form-select-option>
              <b-form-select-option value="3">Фрезерування по дузі</b-form-select-option>
              <b-form-select-option value="4">Вибірка чверті</b-form-select-option>
              <b-form-select-option value="5">Паз під ДВП</b-form-select-option>
              <b-form-select-option value="6">Нестандартне фрезерування</b-form-select-option>
              <b-form-select-option value="7">Різ під нахилом пили</b-form-select-option>
              <b-form-select-option value="8">Фрезерування під петлі</b-form-select-option>

            </b-form-select>
          </td>
          <td style="min-width: 110px !important;">
            <select class="custom-select" v-if="detailsName.length > 0"
                    v-model="detail.name"
                    @keyup.enter="focusNextInput">
              <option value="">Ні</option>

              <option v-for="(option, j) in detailsName" v-if="option.name.length > 0" :value="option.name"
                      :key="j">
                {{ option.name }}
              </option>
            </select>
          </td>
          <td style="min-width: 110px !important;">
            <span>
              <input class="one" placeholder="Деталь" type="text" @keyup.enter="focusNextInput"
                     @click="ifSelect($event)" v-model="detail.detail_name"/>
            </span>
          </td>

        </tr>
        </tbody>
      </table>
      <button class="button"
              type="button"
              v-hotkey="keymap"
              :disabled="materials.length == 0" id="addNewDetail"
              @click="addNewDetail"> Додати (Alt +)
      </button>
    </form>
  </div>
</template>

<script>
import $ from 'jquery';
import DetailModal from "@/components/modals/detailModal";


export default {
  data() {
    return {
      groups: app.groups,
      activeIndex: 0,
      rotateAllValue: 0,
      base_template: 1,
    }
  },
  computed: {
    keymap() {
      return {
        'alt++': this.addDetailHotKey
      }
    },
    order() {
      return this.$store.state.data.order
    },
    details: {
      get: function () {
        return this.$store.state.data.details
      },
      set: function (newValue) {
        this.$store.commit('data/SET_DETAILS', newValue)
      }

    },
    materials() {
      return this.$store.state.data.materials;
    },
    edges() {
      return this.$store.state.data.edges;
    },
    detailsName() {
      return this.$store.state.data.detailsNames;
    },
    resp() {
      return this.$store.state.data.response;
    },
    detail() {
      return this.$store.state.data.detail;
    },
    screeds() {
      return this.$store.state.data.screeds;
    }
  },
  methods: {
    addDetailHotKey() {
      if (this.materials.length !== 0) {
        this.addNewDetail()
      }
    },
    detailValidation(i, p) {
      if (this.resp && this.resp.Parts != undefined) {
        let item = this.resp.Parts.find(part => part.No == i),
            response = item != undefined && item[p] != undefined ? 'Вірно: ' + item[p] : '';
        if (p === 'Template_' && item) {
          if (item['P1_'] != undefined ||
              item['P2_'] != undefined ||
              item['P3_'] != undefined ||
              item['P4_'] != undefined ||
              item['EdgeA1_'] != undefined
          ) {
            response = 'Помилка у розширеному деталюванні';
          }
        }

        if (item && item[p] === 0) {
          if (p.includes('Edge')) {
            response = 'Вірно: Ні';
          }
          if (p.includes('rotate') || p.includes('screed_bp')) {
            response = 'Вірно: Викл(0)';
          }
        }

        return response;
      }
      return '';
    },
    rotateAll() {
      this.rotateAllValue = this.rotateAllValue == 1 ? 0 : 1;
      this.details.forEach((item) => item.rotate = this.rotateAllValue)
    },
    addEdge(v, i, t) {

      let defEdge = this.$store.getters['data/defaultEdge'];

      if (this.edges.length === 0 || defEdge === undefined) return;

      if (v < 0) v = 0;

      if (v > 0 && t == 1) {
        this.details[i].edge_t = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_b = defEdge.ref;
        } else {
          this.details[i].edge_b = null;
        }
      } else if (v == 0 && t == 1) {
        this.details[i].edge_t = null;
        this.details[i].edge_b = null;
      }

      if (v > 0 && t == 2) {
        this.details[i].edge_l = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_r = defEdge.ref;
        } else {
          this.details[i].edge_r = null;
        }
      } else if (v == 0 && t == 2) {
        this.details[i].edge_l = null;
        this.details[i].edge_r = null;
      }
    },
    ifSelect(e) {
      if (typeof e.target.select == 'function') {
        e.target.select();
      }
    },
    focusNextInput(e) {
      let nextInput = e.target.parentElement.nextElementSibling || e.target.parentElement.parentElement.nextElementSibling;
      nextInput = nextInput.querySelector('input') || nextInput.querySelector('select');
      if (nextInput) {
        nextInput.focus();
        if (typeof nextInput.select == 'function') {
          nextInput.select();
        }
      }
    },
    copyDetail() {
      let d = {...this.detail};
      this.details.push(d);
    },
    removeDetail(i) {
      this.details.splice(i, 1);

      if (this.details.length === 0) {
        this.$store.commit('data/SET_DETAIL', {});
      } else {
        this.$store.commit('data/SET_DETAIL', this.details[this.details.length - 1]);
        setTimeout(() => {
          if (document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child')) {
            document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child').select()
          }
        }, 500);
      }
    },
    processDetailsNo() {
      this.details = this.details.map((detail, index) => {
        detail.No = index + 1;
        return detail;
      })
    },
    addNewDetail() {
      let cleanDeteil = {
        "No": this.details.length > 0 ? this.details[this.details.length - 1]['No'] + 1 : 1,
        "length": 20,
        "width": 20,
        "count": 1,
        "rotate": 0,
        "edge_t": null,
        "edge_b": null,
        "edge_l": null,
        "edge_r": null,
        "material": this.$store.getters['data/defaultMaterial'].ref || '',
        "note": '',
        "template": 1,
        "p1": 0,
        "p2": 0,
        "p3": 0,
        "p4": 0,
        "edge_template": null,
        "screed": 0,
        "screed_bp": 0,
        "cut_to_txtrs": 0,
        "name": '',
        "detail_name": '',
        "file": '',
      };
      this.details.push(cleanDeteil);

      this.visual((this.details.length - 1));

      setTimeout(() => {
        if (document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child')) {
          document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child').select()
        }
      }, 500);
    },
    pClear(i) {
      this.detail.p1 = 0;
      this.detail.p2 = 0;
      this.detail.p3 = 0;
      this.detail.p4 = 0;
      this.detail.edge_template = null;
      this.detail.note = '';
      this.detail.file = '';

      this.details[i].p1 = 0;
      this.details[i].p2 = 0;
      this.details[i].p3 = 0;
      this.details[i].p4 = 0;
      this.details[i].edge_template = null;
      this.details[i].note = '';
      this.details[i].file = '';
      this.$store.commit('data/SET_DETAIL', this.detail)
      this.$store.commit('data/SET_DETAILS', this.details)


      let itemKey = Object.keys(this.resp.Parts).find(key => this.resp.Parts[key].No == i + 1);
      if (itemKey) {
        if (this.resp.Parts[itemKey]['P1_'] != undefined) {
          this.resp.Parts[itemKey]['P1_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P2_'] != undefined) {
          this.resp.Parts[itemKey]['P2_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P3_'] != undefined) {
          this.resp.Parts[itemKey]['P3_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['P4_'] != undefined) {
          this.resp.Parts[itemKey]['P4_'] = undefined;
        }
        if (this.resp.Parts[itemKey]['EdgeA1_'] != undefined) {
          this.resp.Parts[itemKey]['EdgeA1_'] = undefined;
        }
      }
    },
    visual(i) {
      this.$store.commit('data/SET_DETAIL', this.details[i])
      app.detail = this.details[i];
      this.activeIndex = i;
    },
    checkbox(e, v) {
      if (e.key == 0) {
        this.detail[v] = 0;
      } else if (e.key == 1) {
        this.detail[v] = 1;
      }
    },
    checkboxChange(val, v) {
      this.detail[v] = val ? 1 : 0;
    },
    onFileChanged($event, v) {
      const target = $event.target;
      if (target && target.files) {
        this.detail[v] = target.files[0];
      }
    },
    selectByKey(e, data, v) {
      let val = e.key;
      if (+(val - 1) != NaN && data[val - 1] != undefined && data[val - 1].ref != undefined) {
        this.detail[v] = data[val - 1].ref;
      }
      if (val == 0 && v != 'template') {
        this.detail[v] = null;
      }
    },
    onlyNumeric(e) {
      if (e.key != '.' && e.key != ',') {
        e.target.value = e.target.value.replace(/[^0-9\.\,]+/g, '')
      }

    }
  },
  mounted() {
    if (this.details.length && (!app.detail || !app.detail.No)) {
      app.detail = this.details[(this.details.length - 1)];
    }
    setTimeout(() => {
      if (document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child')) {
        document.querySelector('[data-key="' + (this.details.length - 1) + '"] input:first-child').select()
      }
    }, 300);

    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });

    document.addEventListener("keydown", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });


    $(function () {

      $('[data-toggle="tooltip"]').each((i, el) => {
        $(el).tooltip({boundary: "window"});
      });
    });

    this.$root.$on('update', i => {
      this.$forceUpdate();
    });

  },
  components: {
    DetailModal
  }
}
</script>
<style>
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table input {
  border: none !important;
}

.main_table tbody {
  display: block;
  max-height: 605px;
  overflow-y: auto;
  padding-bottom: 25px;
}

.main_table thead {
  display: table;
  table-layout: fixed;
}

.table {
  font-size: 14px;
}

.edge_table .main_table td, .main_table th {
  padding: .2rem !important;
}

.edge_table {
  font-size: 12px;
}

.main_table {
  width: 2430px !important;
  padding-bottom: 5px !important;
}

.main_table td, .main_table th {
  padding: .15rem !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.main_table td input:not([type='checkbox']) {
  width: 46px;
  border: none;
  padding: 6px 0 7px 3px;
  margin: -2px;
}

.main_table td .one {
  width: 55px !important;
  border: none;
}

.main_table td .one-qt {
  width: 28px !important;
  border: none;
}

.main_table td .sm-one {
  width: 15px !important;
  border: none;
}

.main_table td .two {
  width: 70px;
  border: none;
}

.main_table td .three {
  width: 100%;
  border: none;
}

.main_table input[name="note"] {
  width: 100px;
}

.main_table td select {
  width: 100px;
  border: none;
  font-size: 12px !important;
}

.card-header {
  padding: .5rem .5rem !important;
}

.card h5 p {
  font-size: 11px !important;
  margin-bottom: 0px;
}

tr.active {
  background-color: #d2d2d2;
}

tr.active * {
  background-color: #d2d2d2;
}
</style>
