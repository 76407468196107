const template = {
    config: {
        d3: null,
        svg: null,
        H: null,
        W: null,
        plus: null
    },
    bias: 13,
    edgesList: [],
    detail: null,

    init: function (config) {
        this.config.d3 = config.d3;
        this.config.svg = config.svg;
        this.config.H = config.H;
        this.config.W = config.W;
        this.config.plus = config.plus;
    },

    scxy: function (h, w) {
        let a = this.config.H,
            b = this.config.W,
            x = w,
            y = h;

        if (x < 20 || y < 20) return [100, 100];

        //Приведение по Y (высоте)
        y = a;
        x = (x * a) / h;
        //Приведение по X (длине)
        if (x > b) { // if X > 1000(600)
            x = b;
            y = (b * h) / w;
        }

        return [y, x];
    },

    scAdapt: function () {
        return this.scxy(this.detail.width, this.detail.length);
    },

    draw: function (detail, edges, materials = []) {
        //Очистка
        this.edgesList = edges;
        this.config.svg.selectAll("*").remove();

        this.detail = detail;
        let scL = this.scAdapt()[1], //Длина(Ширина)
            scW = this.scAdapt()[0];//Высота

        //Отрисовка
        this.paint(scL, scW);
    },

    paint(L, W) {
        this.config.svg
            .attr('height', (this.config.H + this.bias * 4) + 5).attr('width', (this.config.W + this.bias * 4) + 5);

        this.config.svg
            .append('rect')
            .attr('width', L)
            .attr('height', W)
            .attr('transform', 'translate(' + (this.config.plus + this.bias * 2) + ',' + (this.config.plus + this.bias * 2) + ')')
            .style('fill', '#f5f5f5')
            .attr('stroke-width', '3')
            .attr('stroke', 'black');

        this.lines(L, W);
        this.edges(L, W);
        this.arrow();
        this.dimensions(L, W);
    },

    lines(L, W) {
        let colorT = this.edgesList.find(el => el.ref === this.detail.edge_t)?.color || '#f5f5f5';
        let colorB = this.edgesList.find(el => el.ref === this.detail.edge_b)?.color || '#f5f5f5';
        let colorL = this.edgesList.find(el => el.ref === this.detail.edge_l)?.color || '#f5f5f5';
        let colorR = this.edgesList.find(el => el.ref === this.detail.edge_r)?.color || '#f5f5f5';
        let baseL = L;
        let baseW = W;
        L = L + (this.config.plus * 2) + this.bias * 4;
        W = W + (this.config.plus * 2) + this.bias * 4;
        //направление рисунка

        // top line
        this.config.svg
            .append('line')
            .attr('x1', this.config.plus + this.bias * 2)
            .attr('y1', this.config.plus + this.bias * 2 - 5)
            .attr('x2', (this.config.plus + this.bias * 2) + baseL)
            .attr('y2', this.config.plus + this.bias * 2 - 5)
            .attr('stroke-width', 3)
            .attr('stroke', colorT);

        // right line
        this.config.svg
            .append('line')
            .attr('x1', (this.config.plus + this.bias * 2) + baseL + 5)
            .attr('y1', this.config.plus + this.bias * 2)
            .attr('x2', (this.config.plus + this.bias * 2) + baseL + 5)
            .attr('y2', (this.config.plus + this.bias * 2) + baseW)
            .attr('stroke-width', 3)
            .attr('stroke', colorR);

        // bottom line
        this.config.svg
            .append('line')
            .attr('x1', (this.config.plus + this.bias * 2) + baseL)
            .attr('y1', (this.config.plus + this.bias * 2) + baseW + 5)
            .attr('x2', (this.config.plus + this.bias * 2))
            .attr('y2', (this.config.plus + this.bias * 2) + baseW + 5)
            .attr('stroke-width', 3)
            .attr('stroke', colorB);

        // left line
        this.config.svg
            .append('line')
            .attr('x1', this.config.plus + this.bias * 2 - 5)
            .attr('y1', (this.config.plus + this.bias * 2) + baseW)
            .attr('x2', this.config.plus + this.bias * 2 - 5)
            .attr('y2', this.config.plus + this.bias * 2)
            .attr('stroke-width', 3)
            .attr('stroke', colorL);

        this.config.svg
            .append('line')
            .attr('x1', L / 2 - L / 10)
            .attr('x2', L / 2 + L / 10)
            .attr('y1', W / 2 - W / 12)
            .attr('y2', W / 2 - W / 12)
            .attr('stroke', 'black');

        this.config.svg
            .append('line')
            .attr('x1', L / 2 - L / 6)
            .attr('x2', L / 2 + L / 6)
            .attr('y1', W / 2)
            .attr('y2', W / 2)
            .attr('stroke', 'black');

        this.config.svg
            .append('line')
            .attr('x1', L / 2 - L / 10)
            .attr('x2', L / 2 + L / 10)
            .attr('y1', W / 2 + W / 12)
            .attr('y2', W / 2 + W / 12)
            .attr('stroke', 'black');
    },
    edges(L, W) {
        try {

            let edgesContainer = this.config.svg.append("g").attr('transform', 'translate(' + (this.bias) + ',' + (this.bias) + ')');

            let colorT = this.edgesList.find(el => el.ref === this.detail.edge_t)?.color || 'red';
            let colorB = this.edgesList.find(el => el.ref === this.detail.edge_b)?.color || 'red';
            let colorL = this.edgesList.find(el => el.ref === this.detail.edge_l)?.color || 'red';
            let colorR = this.edgesList.find(el => el.ref === this.detail.edge_r)?.color || 'red';


            if (this.detail.edge_t) {
                edgesContainer
                    .append('text')
                    .attr("x", (this.config.plus + (this.bias) + L / 2))
                    .attr("y", (this.config.plus + this.bias) - 10)
                    .style("text-anchor", "middle")
                    .text(L >= 200 ? this.edgesList.find(el => el.ref === this.detail.edge_t)?.full_name : "")
                    .attr("font-family", "Tahoma,Verdana,Segoe,sans-serif")
                    .attr("font-size", "11px")
                    .attr("fill", colorT);

                edgesContainer
                    .append('line')
                    .attr("x", (this.config.plus + (this.bias) + L / 2))
                    .attr("y", (this.config.plus + this.bias) - 10)
                    .style("text-anchor", "middle")
                    .text(L >= 200 ? this.edgesList.find(el => el.ref === this.detail.edge_t)?.full_name : "")
                    .attr('stroke', 'black');
            }
            if (this.detail.edge_b) {
                edgesContainer
                    .append('text')
                    .attr("x", (this.config.plus + (this.bias) + L / 2) + 5)
                    .attr("y", ((2 * this.config.plus + this.bias - 1) + W) + 5)
                    .style("text-anchor", "middle")
                    .text(L >= 200 ? this.edgesList.find(el => el.ref === this.detail.edge_b)?.full_name : "")
                    .attr("font-family", "Tahoma,Verdana,Segoe,sans-serif")
                    .attr("font-size", "11px")
                    .attr("fill", colorB);
            }
            if (this.detail.edge_l) {
                edgesContainer
                    .append('text')
                    .style("text-anchor", "middle")
                    .attr("transform", "translate(" + (this.config.plus + (this.bias) - 8) + "," + (this.config.plus + this.bias + W / 2) + ") rotate(270)")
                    .text(W >= 200 ? this.edgesList.find(el => el.ref === this.detail.edge_l)?.full_name : "")
                    .attr("font-family", "Tahoma,Verdana,Segoe,sans-serif")
                    .attr("font-size", "11px")
                    .attr("fill", colorL);
            }
            if (this.detail.edge_r) {
                edgesContainer
                    .append('text')
                    .style("text-anchor", "middle")
                    .attr("transform", "translate(" + (this.config.plus + L + this.config.plus + (this.bias) + 4) + "," + (this.config.plus + this.bias + W / 2) + ") rotate(270)")
                    .text(W >= 200 ? this.edgesList.find(el => el.ref === this.detail.edge_r).full_name : "")
                    .attr("font-family", "Tahoma,Verdana,Segoe,sans-serif")
                    .attr("font-size", "11px")
                    .attr("fill", colorR);
            }
        } catch (e) {
            console.log(e);
        }
    },
    arrow() {
        let defs = this.config.svg.append('defs');

        defs.append("marker")
            .attr('id', 'startarrow')
            .attr('markerWidth', '10')
            .attr('markerHeight', '5')
            .attr('refX', '10')
            .attr('refY', '2.5')
            .attr('orient', 'auto')

            .append("polygon")
            .attr('points', '10 0, 10 5, 0 2.5')
            .attr('fill', 'black');

        defs.append("marker")
            .attr('id', 'endarrow')
            .attr('markerWidth', '10')
            .attr('markerHeight', '5')
            .attr('refX', '0')
            .attr('refY', '2.5')
            .attr('orient', 'auto')
            .attr('markerUnits', 'strokeWidth')

            .append("polygon")
            .attr('points', '0 0, 10 2.5, 0 5')
            .attr('fill', 'black');
    },
    dimensions(L, W) {

        let dimensionsContainer = this.config.svg.append("g").attr('transform', 'translate(' + (this.bias) + ',' + (this.bias) + ')');


        if (this.detail.length >= 20) {
            dimensionsContainer.append('line')
                .attr('x1', this.config.plus + this.bias)
                .attr('y1', 0)
                .attr('x2', this.config.plus + this.bias)
                .attr('y2', this.config.plus + this.bias)
                .attr('stroke', 'grey');

            dimensionsContainer.append('line')
                .attr('x1', this.config.plus + this.bias + L)
                .attr('y1', 0)
                .attr('x2', this.config.plus + this.bias + L)
                .attr('y2', this.config.plus + this.bias)
                .attr('stroke', 'grey');

            dimensionsContainer.append('line')
                .attr('x1', this.config.plus + this.bias + 10)
                .attr('y1', this.config.plus - 10)
                .attr('x2', this.config.plus + this.bias + L - 10)
                .attr('y2', this.config.plus - 10)
                .attr('stroke', 'grey')
                .attr('marker-start', 'url(#startarrow)')
                .attr('marker-end', 'url(#endarrow)');

            dimensionsContainer.append('text')
                .text(this.detail.length)
                .attr('stroke', 'black')
                .attr('font-size', '12px')
                .attr('transform', "translate(" + (this.config.plus + this.bias + (L / 2)) + "," + (this.config.plus - 14) + ")")
                .style("text-anchor", "middle");


        }
        if (this.detail.width >= 20) {
            dimensionsContainer.append('line')
                .attr('x1', 0)
                .attr('y1', this.config.plus + this.bias)
                .attr('x2', this.config.plus + this.bias)
                .attr('y2', this.config.plus + this.bias)
                .attr('stroke', 'grey');

            dimensionsContainer.append('line')
                .attr('x1', 0)
                .attr('y1', this.config.plus + this.bias + W)
                .attr('x2', this.config.plus + this.bias)
                .attr('y2', this.config.plus + this.bias + W)
                .attr('stroke', 'grey');

            dimensionsContainer.append('line')
                .attr('x1', this.config.plus - 10)
                .attr('y1', this.config.plus + this.bias + 10)
                .attr('x2', this.config.plus - 10)
                .attr('y2', this.config.plus + this.bias + W - 10)
                .attr('stroke', 'grey')
                .attr('marker-start', 'url(#startarrow)')
                .attr('marker-end', 'url(#endarrow)');

            dimensionsContainer.append('text')
                .text(this.detail.width)
                .attr('stroke', 'black')
                .attr('font-size', '12px')
                .attr('transform', "translate(" + (this.config.plus - 14) + "," + (this.config.plus + this.bias + (W / 2)) + ") rotate(270)")
                .style("text-anchor", "middle");
        }
    }
};

export default template;
